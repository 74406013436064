import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Image from "../Image";
import Translation, { useTranslation } from "../Translation";
import Article from "../Article";
import Button from "../Button";

const NotFound = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(
    graphql`
      query {
        error: file(relativePath: { eq: "404.png" }) {
          ...DesktopImage
        }
      }
    `
  );

  return (
    <section className="not-found">
      <Article>
        <div className="w-full flex flex-col items-center px-8 py-16 tablet:px-32 tablet:py-24  bg-white">
          <div style={{ maxWidth: "574px", width: "100%" }}>
            <Image fluid={data.error.childImageSharp.fluid} />
          </div>
          <p className="pt-12 pb-4 tablet:py-8 text-center tablet:text-left">
            <Translation id="page-not-found" />
          </p>
          <div className="mx-16 tablet:mx-0">
            <Button
              color="green"
              size="large"
              href="/"
              text={t("go-to-homepage")}
            /></div>
        </div>
      </Article>
    </section>
  );
};
export default NotFound;
