import React from "react";

import Layout from "../components/Layout/Layout";
import NotFound from "../components/Markup/NotFound";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO name="not-found" baseName="404" />
    <NotFound />
  </Layout>
);

export default NotFoundPage;
